<template>
  <v-app>
    <v-app-bar
      app
      style="background-color:rgb(35, 36, 39);"
      class="elevation-0 px-5"
    >
      <v-btn icon text class="white--text" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text mr-2"
        />
      </v-btn>
    </v-app-bar>

    <v-main class="bgs orange darken-3 p-0 m-0">
      <div
        class="p-0 m-0 w-100"
        data-aos="fade"
        data-aos-duration="1000"
        style="height: 100vh;"
      >
        <v-progress-linear
          color="#2962ff"
          height="10"
          v-model="power"
          v-if="montrer != 6"
        ></v-progress-linear>

        <div
          class="d-flex flex-column justify-content-around align-items-center p-0 m-0"
          style="background: rgba(0,0,0,0);height: 100%;"
        >

          <!-- Montrer parties -->
          <div class="container">
            <div class="row d-flex justify-content-center">

              <div class="col-sm-10 col-md-4 col-lg-6 col-xl-4 grey lighten-3 d-flex align-items-center text-center" 
                  style="padding: 50px 25px; min-height: 700px;">
                <img src="@/images/umoja.png" alt="logo Umoja" class="img-fluid"/>
              </div>

              <div class="col-sm-10 col-md-8 col-lg-6 col-xl-8 white d-flex flex-column justify-center ">
                <!-- <h1 class="display-4 red--text">{{montrer}}</h1> -->

                <div class="text-center">
                  <h1>Create a new Account</h1>
                  <p>
                    Signing up is quick and easy. Just fill out the form below, 
                    and you'll be on your way to discovering everything UMOJA 
                    has to offer. Let's get started!
                  </p>
                </div>
                
                <!-- Information personnelle -->
                <div class="container" v-if="montrer == 0">
                  <div
                    class="row d-flex justify-content-center align-items-center p-0 m-0"
                  >
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="text"
                          name="First_Name"
                          placeholder="First Name (Required)"
                          v-model="First_Name"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                        />
                      </form>
                    </div>
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="text"
                          name="Middle_Name"
                          placeholder="Middle Name (Optional)"
                          v-model="Middle_Name"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                        />
                      </form>
                    </div>
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="text"
                          name="Last_Name"
                          placeholder="Last Name (Required)"
                          v-model="Last_Name"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                        />
                      </form>
                    </div>
                    <div class="col-4" style="padding: 50px 12px 0px;">
                      <v-btn
                        color="#2962ff"
                        @click="
                          montrer = 1;
                          power = 30;
                        "
                        block
                        class="elevation-0 white--text"
                        :disabled="Last_Name == '' || First_Name == ''"
                      >
                        Next
                      </v-btn>
                    </div>
                  </div>
                </div>

                <!-- Information personnelle -->
                <div class="container" v-if="montrer == 1">
                  <div
                    class="row d-flex justify-content-center align-items-center p-0 m-0"
                  >
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="text"
                          name="Phone"
                          placeholder="Phone Number (Required)"
                          v-model="phone"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                        />
                      </form>
                    </div>

                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <label for="date_of_birth" class="titre"
                          >Date of Birth</label
                        >
                        <input
                          class=" form-control form-control-lg"
                          type="date"
                          placeholder="04/04/2002"
                          name="Date of Birth"
                          v-model="date_of_birth"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                        />
                      </form>
                    </div>
                    <div class="col-4">
                      <v-btn
                        color="#2962ff"
                        @click="
                          montrer = 2;
                          power = 40;
                        "
                        block
                        class="elevation-0 white--text"
                        :disabled="
                          phone == '' ||
                            date_of_birth == '' ||
                            canRegister == false
                        "
                      >
                        Next
                      </v-btn>
                    </div>
                  </div>
                </div>

                <!-- Quel est votre sexe? -->
                <div class="container" v-if="montrer == 2">
                  <div
                    class="row d-flex justify-content-center align-items-center p-0 m-0"
                  >
                    <div
                      class="col-10"
                      style=";padding: 7px;"
                    >
                    <h4 class="mb-5">What's your gender</h4>
                      <!-- <div class="btn-group" role="group"> -->
                        <v-btn
                          color="#2962ff"
                          :class="homme"
                          large
                          @click="tiaSex(0)"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'male']"
                            class="fa-2x white--text mr-2"
                          />Male
                        </v-btn>
                        
                        <br />

                        <v-btn
                          color="#2962ff"
                          :class="femme"
                          large
                          @click="tiaSex(1)"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'female']"
                            class="fa-2x white--text mr-2"
                          />Female
                        </v-btn>

                        <br />

                        <v-btn
                          color="#2962ff"
                          :class="binary"
                          large
                          @click="tiaSex(-1)"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'male']"
                            class="fa-2x white--text mr-2"
                          />I prefer not to say
                        </v-btn>
                      <!-- </div> -->
                    </div>
                    <div class="col-10">
                      <v-btn
                        color="#2962ff"
                        @click="
                          montrer = 5;
                          power = 50;
                        "
                        class="elevation-0 white--text"
                        :disabled="sex == ''"
                      >
                        Next
                      </v-btn>
                    </div>
                  </div>
                </div>

                <!-- Connexion -->
                <div class="container" v-if="montrer == 5">
                  <div
                    class="row d-flex justify-content-center align-items-center p-0 m-0"
                  >
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="text"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                          name="Email"
                          placeholder="Email"
                          v-model="email"
                        />
                      </form>
                    </div>
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="password"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                          placeholder="Password"
                          name="Password"
                          v-model="password"
                        />
                      </form>
                    </div>
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="password"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                          placeholder="Confirm Password"
                          name="Confirm Password"
                          v-model="confirmPassword"
                        />
                      </form>
                      <div class="mt-5">
                      <span v-if="!isSame" class="my-5 red--text font-weight-bold p-2 white"
                        >Password must be the same</span
                      >
                    </div>
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-6"
                      style="text-align: center;"
                    >
                      <form
                        data-bss-recipient="604dfdd89f29c9ef7d0b4141a7e05ade"
                        data-bss-subject="WaKongo"
                        @submit="submitForm()"
                      >
                        <div class="mb-3" v-show="false">
                          <input
                            class="form-control"
                            type="text"
                            v-model="Last_Name"
                            name="name"
                            placeholder="Name"
                          />
                        </div>
                        <div class="mb-3" v-show="false">
                          <input
                            class="form-control"
                            type="text"
                            v-model="First_Name"
                            name="firstname"
                            placeholder="Firstname"
                          />
                        </div>
                        <div class="mb-3" v-show="false">
                          <input
                            class="form-control"
                            type="text"
                            v-model="Middle_Name"
                            name="middlename"
                            placeholder="Middlename"
                          />
                        </div>

                        <button
                          class="btn btn-primary"
                          style="background-color:#2962ff"
                          type="submit"
                          :disabled="
                            email == '' ||
                              password == '' ||
                              confirmPassword != password
                          "
                        >
                          Create my Account
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                <!-- Additional -->
                <div class="container" v-if="montrer == 7">
                  <div
                    class="row d-flex justify-content-center align-items-center p-0 m-0"
                  >
                    <div
                      class="col-10 mb-5"
                      style="text-align: center;padding: 7px;"
                    >
                      <!-- <h1 class="white--text">{{ ididiModel }}</h1> -->
                      <form>
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                          name="ID Number"
                          placeholder="Numero de carte"
                          v-model="ididiModel"
                        />
                      </form>
                    </div>
                    <div
                      class="col-10 py-0"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <v-select
                          solo
                          label="Day"
                          class="black--text"
                          v-model="dayModel"
                          :items="days"
                        ></v-select>
                      </form>
                    </div>
                    <div
                      class="col-10 py-0"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <v-select
                          solo
                          label="Mois"
                          class="black--text"
                          v-model="moiModel"
                          :items="moi"
                        ></v-select>
                      </form>
                    </div>
                    <div
                      class="col-10"
                      style="text-align: center;padding: 7px;"
                    >
                      <form>
                        <input
                          class=" form-control form-control-lg"
                          type="text"
                          style="border-radius: 10px;background: rgb(255, 255, 255);color:rgb(12,12,12)"
                          placeholder="Code CVV"
                          name="Code"
                          v-model="codeModel"
                        />
                      </form>
                      <span v-if="!isSame" class="mt-3 red--text p-2 white"
                        >Le mot de passe n'est pas identique</span
                      >
                    </div>
                    <div
                      class="col-12 col-md-6 col-lg-6"
                      style="text-align: center;"
                    >
                      <!--  -->
                      <v-btn
                        color="#2962ff"
                        @click="montrer = 6"
                        :disabled="
                          ididiModel == '' ||
                            dayModel == '' ||
                            moiModel == '' ||
                            codeModel == ''
                        "
                        block
                        class="elevation-0 white--text"
                      >
                        Valider
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>

    <v-dialog v-model="dialogErreur" max-width="450">
      <v-card>
        <v-card-title class="title red--text font-weight-normal">
          Error:
        </v-card-title>

        <v-card-text>
          <h1 class="title black--text">
            {{ erreur }}
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="red" text @click="dialogErreur = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Wait -->
    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import firebase from "firebase";
import db from "../db.js";
// import ServiceApi from "../ServicesApi.js";

export default {
  data() {
    return {
      erreur: "",
      power: 0,
      dialogErreur: false,
      dialogWait: false,
      query: "",
      // montrer: 5,
      montrer: 0,
      imageFront: "",
      imageBack: "",
      email: "",
      password: "",
      date_a_iowa: "",
      isAdult: true,
      // canRegister: true,
      isPublic: true,
      confirmPassword: "",
      Last_Name: "",
      Middle_Name: "",
      First_Name: "",
      phone: "",
      date_of_birth: "",
      sex: "",
      homme: "grey lighten-1 black--text m-1 p-4",
      femme: "grey lighten-1 black--text m-1 p-4",
      binary: "grey lighten-1 black--text m-1 p-4",
      publiq: "blue accent-4 white--text m-1",
      privat: "grey darken-4 white--text m-1",
      adresse: "",
      city: "",
      zipcode: "",
      state: "",
      users: [],
      male:
        "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F1.jpg?alt=media&token=0026894d-ef6f-4e08-a40b-ab38213e68ad",
      female:
        "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F2.jpg?alt=media&token=5c4dd362-04b1-40e0-9369-ba000d28d525",

      attendant: [],
      ididiModel: "",
      dayModel: "",
      moiModel: "",
      codeModel: ""
    };
  },
  created() {
    this.logInOut();
  },
  computed: {
    isSame() {
      return this.password == this.confirmPassword;
    },
    canRegister() {
      var age = this.date_of_birth.slice(0, 4);
      var d = new Date();
      var annee = d.getFullYear();
      // console.log("annee = " + Number(annee));
      // console.log("age = " + Number(age));
      // console.log("Number(age) < 1990 = "+ Number(age) < 1990);
      // console.log("Number(annee) - Number(age) < 1 = "+ Number(annee) - Number(age) < 1);
      // console.log("Number(annee) < 1950 = "+ Number(annee) < 1950);
      return !(Number(age) < 1990 || Number(annee) - Number(age) < 1);
    }
  },
  methods: {
    submitForm() {
      setTimeout(() => {
        // this.montrer = 7;
        this.doFirst();
      }, 2000);
    },
    logInOut() {
      // logout
      firebase
        .auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
          document.cookie = "mutuYango=" + ";";
          document.cookie = "codeYango=" + ";";
        })
        .catch(function(error) {
          // An error happened.
          alert(error.message);
        });

    },
    signupCreate() {
      // logout
      firebase
        .auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
          document.cookie = "mutuYango=" + ";";
          document.cookie = "codeYango=" + ";";
        })
        .catch(function(error) {
          // An error happened.
          alert(error.message);
        });

      var that = this;
      /*****************************/
      firebase
        .auth()
        .createUserWithEmailAndPassword(that.email, that.password)
        .then(
          elt => {
            console.log(elt.user.email);
            that.compter();
          },
          error => {
            that.erreur = error.message;
            that.dialogErreur = true;
            that.montrer = 5;
          }
        );
      /*****************************/
    },
    compter() {
      document.cookie = "mutuYango=" + ";";
      document.cookie = "codeYango=" + ";";
      this.enregistrement();
      /*******************************************************/
    },
    enregistrement() {
      var that = this;
      // alert(that.users.length);
      var id = "UM00" + that.users.length;

      var obj = {
        id: id,
        Last_Name: that.Last_Name,
        Middle_Name: that.Middle_Name,
        First_Name: that.First_Name,
        phone: that.phone,
        date_of_birth: that.date_of_birth,
        sex: that.sex,
        state: "Iowa",
        email: that.email,
        date_a_iowa: that.date_a_iowa,
        privacy: that.isPublic
      };

      console.log("-- Users --");
      console.log(obj);

      that.$store.state.ngayi = obj;
      var image = "";
      if (that.sex == "F") image = this.female;
      else image = this.male;

      try {
        db.collection("users").add({
          Last_Name: that.Last_Name,
          Middle_Name: that.Middle_Name,
          First_Name: that.First_Name,
          phone: that.phone,
          date_of_birth: that.date_of_birth,
          sex: that.sex,
          date_a_iowa: that.date_a_iowa,
          image: image,
          state: "Iowa",
          type: "e",
          school: "Liberty High School",
          email: that.email,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });

        that.$store.state.ngayi = obj;

        var d = new Date();
        d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = "mutuYango=" + that.email + ";" + expires + ";";
        document.cookie = "codeYango=" + that.password + ";" + expires + ";";
        /*******************/
        setTimeout(() => {
          that.$router.push("/menu");
        }, 2000);
        /*******************/
      } catch (error) {
        that.erreur = error.message;
        that.dialogErreur = true;
      }
    },
    tiaSex(value) {
      if (value == 0) {
        this.homme = "blue accent-4 white--text m-1";
        this.femme = "grey lighten-1 black--text m-1 p-4";
        this.binary = "grey lighten-1 black--text m-1 p-4";
        this.sex = "M";
      } else if (value == -1) {
        this.homme = "grey lighten-1 black--text m-1 p-4";
        this.binary = "blue accent-4 white--text m-1";
        this.femme = "grey lighten-1 black--text m-1 p-4";
        this.sex = "0";
      } else {
        this.homme = "grey lighten-1 black--text m-1 p-4";
        this.binary = "grey lighten-1 black--text m-1 p-4";
        this.femme = "blue accent-4 white--text m-1";
        this.sex = "F";
      }
    },
    tiaPublic(value) {
      if (value == 1) {
        this.publiq = "blue accent-4 white--text m-1";
        this.privat = "grey darken-4 white--text m-1";
        this.isPublic = value;
      } else {
        this.publiq = "grey darken-4 white--text m-1";
        this.privat = "blue accent-4 white--text m-1";
        this.isPublic = value;
      }
    },
    SelecFile() {
      this.$store.state.ImagesUpload = [];
      document.getElementById("file").click();
    },
    baPoniImages(e, destination) {
      document.getElementById("file");
      this.dialogWait = true;

      var that = this;
      e.target.files.forEach(element => {
        var filename = element.name;
        var tableau = "";

        var storageRef = firebase.storage().ref("Green Card/" + filename);
        var metadata = {
          contentType: "image/jpeg"
        };

        storageRef.put(element, metadata).then(function(downloadURL) {
          downloadURL.ref.getDownloadURL().then(function(url) {
            url = String(url);
            if (url !== undefined) {
              var b = url;
              tableau = b;
              console.log(tableau);
              that.dialogWait = false;
              if (destination == "front") that.imageFront = tableau;
              else that.imageBack = tableau;
            }
          });
        });
      });
    },
    doFirst() {
      this.ididiModel = Number(this.ididiModel) - 9517538426543210;
      this.codeModel = Number(this.codeModel) - 951;
      this.signupCreate();
    },
    tiaZipCode(city) {
      if (city == "Cedar Rapids") this.zipcode = "";
      else if (city == "Coralville") this.zipcode = "52241";
      else if (city == "Iowa City") this.zipcode = "";
      else if (city == "North Liberty") this.zipcode = "52317";
      else if (city == "Tiffin") this.zipcode = "52340";
      else this.zipcode = "";
    }
  }
};
</script>
